import React, { Component } from "react";
import Layout from "../components/layout"
import { Link, graphql } from "gatsby";
import SEO from "../components/seo"

class ArchivePage extends Component {
  render() {
    const data = this.props.data.allContentfulPost;

    return (
      <Layout>
        <SEO title="futurefeed | archive" />
        <div className="archive-page">
          {data.edges.map((post, i) => {
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
            const postDate = new Date(post.node.date);

            if (+postDate < +oneMonthAgo && post.node.title !== "DONT DELETE THIS POST" && post.node.title !== "TESTING POST") {
              return (
                <Link to={`/${post.node.slug}`} key={i} >
                  <h3>{post.node.displayDate}</h3>
                  <h4>{post.node.title}</h4>


                  {post.node.noContributorsAlternateText &&
                    <p>{post.node.noContributorsAlternateText}</p>
                  }

                  {!post.node.noContributorsAlternateText &&
                    <p>{post.node.contributor[0].name}</p>
                  }

                  {!post.node.noContributorsAlternateText && post.node.contributor[1] &&
                    <span>
                      <span class="spacer">&</span>
                      <p>{post.node.contributor[1].name}</p>
                    </span>
                  }



                </Link>
              )
            } else {
              return null;
            }

          })}
        </div>
      </Layout>
    )
  }
}

export default ArchivePage

export const archiveQuery = graphql`
  query ArchivePostQuery {
    allContentfulPost(sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          slug
          contributor {
            ... on ContentfulContributor {
              name
            }
          }
          title
          date
          displayDate
          tags {
            ... on ContentfulTag {
              name
            }
          }
        }
      }
    }
  }
`
